import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parse from "parse";
import { Helmet } from "react-helmet";
import axios from "axios";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FluidPlayer from "../components/FluidPlayer";

class VideoPage extends Component {
  state = {
    date: "",
    title: "",
    status: "",
    postId: 0,
    tube: "",
    tubeKey: "",
    videoSrc: "",
    link: "",
    thumb: "",
    postViews: 0,
    votesCount: 0,
    videoId: "",
    mirror: "direct",
    tags: [],
    sortBy: "updatedAt",
    nextVideos: [],
    history: [],
    saved: [],
  };
  async componentDidMount() {
    await this.getVideoDetails();
    this.getNextVideos();
    this.updateViewCount();
    await this.getUserInfo();
    this.addToHistory();
    if (this.state.tube === "xvideos") {
      // axios
      //   .post(`//api.shudhdesiporn.com/xvideos/${this.state.tubeKey}`)
      //   // .post(`//api.shudhdesiporn.com/xvideos/${this.state.tubeKey}`)
      //   .then((res) => {
      //     this.setState({
      //       videoSrc: res.data,
      //     });
      //   });
      this.setState({
        mirror: "es",
        link: `https://www.xvideos.es/embedframe/${this.state.tubeKey}`,
      });
    } else if (this.state.tube === "pornhub") {
      this.setState({
        link: `https://www.pornhub.org/embed/${this.state.tubeKey}`,
      });
    } else if (this.state.tube === "desixnxx") {
      if (!this.state.keepAlive) {
        this.setState({
          mirror: `direct`,
        });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sortBy !== this.state.sortBy
      //  || prevState.page !== this.state.page
    ) {
      this.getNextVideos();
    }
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.getVideoDetails();
    }
    if (prevState.videoId !== this.state.videoId) {
      this.addToHistory();
    }
    if (prevState.videoId !== this.state.videoId) {
      if (this.state.tube === "xvideos") {
        axios
          .post(`//api.shudhdesiporn.com/xvideos/${this.state.tubeKey}`)
          .then((res) => {
            this.setState({
              videoSrc: res.data,
            });
          });
        this.setState({
          mirror: "es",
          link: `https://www.xvideos.es/embedframe/${this.state.tubeKey}`,
        });
      } else if (this.state.tube === "pornhub") {
        this.setState({
          link: `https://www.pornhub.net/embed/${this.state.tubeKey}`,
        });
      } else if (this.state.tube === "desixnxx") {
        console.log(this.state);
        // if (!this.state.keepAlive) {
        this.setState({
          mirror: `direct`,
        });
        // }
      }
    }
  }
  getVideoDetails = async () => {
    document.querySelector(".loading").style.display = "block";
    const Videos = Parse.Object.extend("Videos");
    const VideosQuery = new Parse.Query(Videos);
    VideosQuery.equalTo("slug", this.props.match.params.slug);
    let VideosArray = await VideosQuery.find();
    // console.log(VideosArray);
    let md = undefined;
    let vt = undefined;
    let st = undefined;
    let ds = undefined;
    if(VideosArray.length > 0){
      if(VideosArray[0].attributes.status!== "deleted"){
        await this.setState({
          md,
          vt,
          st,
          ds,
          ...VideosArray[0].attributes,
          videoId: VideosArray[0].id,
        });
        document.querySelector(".loading").style.display = "none";
        // console.log(VideosArray[0]);
      }else{
      this.props.history.push("/404")
      }
    }else{
      this.props.history.push("/404")
    }
  };
  tags = () => {
    let { tags } = this.state;
    if (this.state.tags.length > 0) {
      let displayTags = [];
      tags.forEach((tag) => {
        // console.log(tag);
        displayTags.push(
          <span key={tag}>
            <Link to="#">{tag}</Link>
          </span>
        );
      });
      return (
        <>
          <h6>Tags :</h6>
          <p className="tags mb-0">{displayTags}</p>
        </>
      );
    }
  };
  getNextVideos = async () => {
    const Videos = Parse.Object.extend("Videos");
    const VideosQuery = new Parse.Query(Videos);
    // VideosQuery.equalTo("status", "published");
    VideosQuery.notEqualTo("status", "deleted");
    // VideosQuery.descending(this.state.sortBy);
    // const count = await VideosQuery.count();
    let random = Math.floor(Math.random() * 1000);
    let VideosArray = await VideosQuery.skip(random).limit(5).find().catch(e=>console.log(e));
    this.setState({ nextVideos: VideosArray });
    // console.log(Video);
    //thumbrotation
    let el = document.querySelectorAll(".thumb");
    el.forEach((e) => {
      e.setAttribute(
        "onmouseover",
        `if (!window.__cfRLUnblockHandlers) return false; if (!window.__cfRLUnblockHandlers) return false; xvideosRotation.start(this)`
      );
      e.setAttribute(
        "onmouseout",
        `if (!window.__cfRLUnblockHandlers) return false; if (!window.__cfRLUnblockHandlers) return false; xvideosRotation.end(this)`
      );
    });
  };
  addToSaved = (videoId) => {
    if (this.state.isLoggedIn) {
      let user = Parse.User.current();
      let { saved } = this.state;
      for (var i = 0; i < saved.length; i++) {
        if (saved[i] === videoId) {
          saved.splice(i, 1);
        }
      }
      saved.unshift(videoId);
      user.set("saved", saved);
      user.save();
    }
  };
  upNext = () => {
    // this.getNextVideos();
    let nextVideos = [];
    let views = "";
    this.state.nextVideos.forEach((video) => {
      //   console.log(video);
      if (video.attributes.postViews >= 1000) {
        views = (video.attributes.postViews / 1000).toFixed(2) + "K";
      } else if (video.attributes.postViews >= 1000000) {
        views = (video.attributes.postViews / 1000000).toFixed(2) + "M";
      } else {
        views = video.attributes.postViews;
      }
      let duration = () => {
        if (video.attributes.duration) {
          return video.attributes.duration;
        }
      };
      nextVideos.push(
        <div key={video.id} className="video-card video-card-list">
          <div className="video-card-image">
            <Link
              className="play-icon"
              to={{ pathname: `/video/${video.attributes.slug}` }}
            >
              <i className="fas fa-play-circle" />
            </Link>
            <Link to={{ pathname: `/video/${video.attributes.slug}` }}>
              <img
                className="img-fluid thumb"
                src={video.attributes.thumb}
                alt=""
              />
            </Link>
            <div className="time">{duration()}</div>
          </div>
          <div className="video-card-body">
            <div className="btn-group float-right right-action">
              <Link
                to={{ pathname: `/video/${video.attributes.slug}` }}
                className="right-action-link text-gray"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v" aria-hidden="true" />
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <a
                  className="dropdown-item"
                  href="##"
                  onClick={() => {
                    this.addToSaved(video.id);
                  }}
                >
                  <i className="fas fa-fw fa-star" /> &nbsp; Save to watch later
                </a>
                {/* <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => {
                        this.setState({ sortBy: "postViews" });
                      }}
                    >
                      <i className="fas fa-fw fa-signal" /> &nbsp; Viewed
                    </a>
                    <a
                      className="dropdown-item"
                      href="##"
                      onClick={() => {
                        this.setState({ sortBy: "updatedAt" });
                      }}
                    >
                      <i className="fas fa-fw fa-clock" /> &nbsp; Recent
                    </a> */}
              </div>
            </div>
            <div className="video-title">
              <Link to={{ pathname: `/video/${video.attributes.slug}` }}>
                {video.attributes.title}
              </Link>
            </div>
            {/* <div className="video-page text-success">
              Education{" "}
              <Link
                title=""
                data-placement="top"
                data-toggle="tooltip"
                to="#"
                data-original-title="Verified"
              >
                <i className="fas fa-check-circle text-success" />
              </Link>
            </div> */}
            <div className="video-view">
              {views} views &nbsp;
              <i className="fas fa-calendar-alt" />{" "}
              {video.updatedAt.toISOString().split("T")[0]}
            </div>
          </div>
        </div>
      );
    });
    return nextVideos;
  };
  updateViewCount = async () => {
    if(this.state.videoId){
      const Videos = Parse.Object.extend("Videos");
      const VideoDetails = new Parse.Query(Videos);
      let Video = await VideoDetails.get(this.state.videoId);
      Video.set("postViews", parseFloat(this.state.postViews) + parseFloat(1));
      await Video.save();
    }
  };
  thumbsUp = async () => {
    const Videos = Parse.Object.extend("Videos");
    const VideoDetails = new Parse.Query(Videos);
    let Video = await VideoDetails.get(this.state.videoId);
    Video.set("votesCount", parseFloat(this.state.votesCount) + parseFloat(1));
    await Video.save();
    document.getElementById(
      "thumbsUp"
    ).innerHTML = `<i class="fas fa-thumbs-up" />`;
    document.getElementById(
      "thumbsDown"
    ).innerHTML = `<i class="far fa-thumbs-down" />`;
    document.getElementById("votes").innerHTML = `Votes: ${
      parseFloat(this.state.votesCount) + parseFloat(1)
    }`;
  };
  thumbsDown = async () => {
    const Videos = Parse.Object.extend("Videos");
    const VideoDetails = new Parse.Query(Videos);
    let Video = await VideoDetails.get(this.state.videoId);
    Video.set("votesCount", parseFloat(this.state.votesCount) - parseFloat(1));
    await Video.save();
    document.getElementById(
      "thumbsDown"
    ).innerHTML = `<i class="fas fa-thumbs-down" />`;
    document.getElementById(
      "thumbsUp"
    ).innerHTML = `<i class="far fa-thumbs-up" />`;
    document.getElementById("votes").innerHTML = `Votes: ${
      parseFloat(this.state.votesCount) - parseFloat(1)
    }`;
  };
  getUserInfo = async () => {
    if (Parse.User.current()) {
      let user = Parse.User.current();
      let history = user.get("history");
      let saved = user.get("saved");
      await this.setState({
        isLoggedIn: true,
        name: user.get("name"),
      });
      if (history) {
        await this.setState({
          history,
        });
      }
      if (saved) {
        await this.setState({
          saved,
        });
      }
    }
  };
  addToHistory = () => {
    if (this.state.isLoggedIn) {
      let user = Parse.User.current();
      let { history, videoId } = this.state;
      for (var i = 0; i < history.length; i++) {
        if (history[i] === videoId) {
          history.splice(i, 1);
        }
      }
      history.unshift(videoId);
      user.set("history", history);
      user.save();
    }
  };
  mirrorButtons = () => {
    let mdMirrorButton;
    // let stMirrorButton;
    let vtMirrorButton;
    let dsMirrorButton;
    if (this.state.tube === "desixnxx") {
      // stMirrorButton = this.state.st && (
      //   <button
      //     onClick={() =>
      //       this.setState({
      //         link: `https://streamtape.com/e/${this.state.st}`,
      //         mirror: `st`,
      //       })
      //     }
      //     type="button"
      //     className="btn btn-outline-secondary mr-2"
      //   >
      //     Mirror 2
      //   </button>
      // );
      vtMirrorButton = this.state.vt && (
        <button
          onClick={() =>
            this.setState({
              link: `https://vup.to/emb.html?${this.state.vt}=img.vup.to/${this.state.vt}`,
              mirror: `vt`,
            })
          }
          type="button"
          className="btn btn-outline-secondary mr-2"
        >
          Mirror 2
        </button>
      );
      mdMirrorButton = this.state.md && (
        <button
          onClick={() =>
            this.setState({
              link: `//mixdrop.co/e/${this.state.md}`,
              mirror: `md`,
            })
          }
          type="button"
          className="btn btn-outline-secondary mr-2"
        >
          Mirror 3
        </button>
      );
      dsMirrorButton = this.state.ds && (
        <button
          onClick={() =>
            this.setState({
              link: `https://dood.to/e/${this.state.ds}`,
              mirror: `ds`,
            })
          }
          type="button"
          className="btn btn-outline-secondary mr-2"
        >
          Mirror 4
        </button>
      );
    }
    if (this.state.tube === "xvideos") {
      return (
        <>
          <button
            onClick={() =>
              this.setState({
                mirror: "direct",
                // link: `https://www.xvideos.es/embedframe/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 1
          </button>
          <button
            onClick={() =>
              this.setState({
                mirror: "es",
                link: `https://www.xvideos.es/embedframe/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 2
          </button>
          <button
            onClick={() =>
              this.setState({
                mirror: "3.com",
                link: `https://www.xvideos3.com/embedframe/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 3
          </button>
          <button
            onClick={() =>
              this.setState({
                mirror: "net",
                link: `https://www.xvideos.net/embedframe/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 4
          </button>
        </>
      );
    } else if (this.state.tube === "pornhub") {
      return (
        <>
          <button
            onClick={() =>
              this.setState({
                link: `https://www.pornhub.net/embed/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 1
          </button>
          <button
            onClick={() =>
              this.setState({
                link: `https://www.pornhub.org/embed/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 2
          </button>
          <button
            onClick={() =>
              this.setState({
                link: `https://www.pornhub.com/embed/${this.state.tubeKey}`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 3
          </button>
        </>
      );
    } else if (this.state.tube === "desixnxx") {
      return (
        <>
          <button
            onClick={() =>
              this.setState({
                mirror: `direct`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 1
          </button>
          {vtMirrorButton}
          {mdMirrorButton}
          {dsMirrorButton}
        </>
      );
    }else {
      return (
        <>
          <button
            onClick={() =>
              this.setState({
                mirror: `direct`,
              })
            }
            type="button"
            className="btn btn-outline-secondary mr-2"
          >
            Mirror 1
          </button>
          {vtMirrorButton}
          {mdMirrorButton}
          {dsMirrorButton}
        </>
      );
    }
  };
  video = () => {
    // console.log(`rendered`);
    if (this.state.tube === "desixnxx") {
      if (this.state.mirror === `direct`) {
        return (
          <>
            {this.mirrorButtons()}
            <div
              className="text-center"
              style={{ width: "100%", height: "480px", margin: "25px 0px" }}
              key={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")}
            >
              <FluidPlayer src={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")} />
            </div>
          </>
        );
      } else {
        return (
          <>
            {this.mirrorButtons()}
            <iframe
              title="videoPlayer"
              width="100%"
              height={480}
              src={this.state.link}
              frameBorder={0}
              allow="autoplay; encrypted-media"
              allowFullScreen
              // sandbox="allow-same-origin allow-scripts"
              className="mt-2"
              key={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")}
            />
          </>
        );
      }
    } else {
      if (this.state.mirror === `direct`) {
        return (
          <>
            {this.mirrorButtons()}
            <div
              className="text-center"
              style={{ width: "100%", height: "480px", margin: "25px 0px" }}
              key={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")}
            >
              <FluidPlayer src={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")} />
            </div>
          </>
        );
      } else {
        return (
          <>
            {this.mirrorButtons()}
            <iframe
              title="videoPlayer"
              width="100%"
              height={480}
              src={this.state.link}
              frameBorder={0}
              allow="autoplay; encrypted-media"
              allowFullScreen
              sandbox="allow-same-origin allow-scripts"
              className="mt-2"
              key={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")}
            />
          </>
        );
      }
    }
  };
  downloadButtons = () => {
    let md;
    let vt;
    let ds;
    // let st

    if (this.state.vt) {
      vt = (
        <a
          target="_blank"
          href={`https://vup.to/${this.state.vt}.html`}
          rel="noopener noreferrer"
          className="btn btn-large btn-primary mr-2 mb-2"
          color="primary"
        >
          Download 2
        </a>
      );
    }

    if (this.state.md) {
      md = (
        <a
          target="_blank"
          href={`//mixdrop.co/f/${this.state.md}?download`}
          rel="noopener noreferrer"
          className="btn btn-large btn-primary mr-2 mb-2"
          color="primary"
        >
          Download 3
        </a>
      );
    }

    if (this.state.ds) {
      ds = (
        <a
          target="_blank"
          href={`https://dood.to/d/${this.state.ds}`}
          rel="noopener noreferrer"
          className="btn btn-large btn-primary mr-2 mb-2"
          color="primary"
        >
          Download 4
        </a>
      );
      // if (this.state.st) {
      //   st = (
      //     <a
      //       target="_blank"
      //       href={`https://dood.to/d/${this.state.ds}`}
      //       rel="noopener noreferrer"
      //       className="btn btn-large btn-primary mr-2 mb-2"
      //       color="primary"
      //     >
      //       Download 4
      //     </a>
      //   );
    }
    return (
      <>
        <a
          // target="_blank"
          href={`${this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")}`}
          rel="noopener noreferrer"
          className="btn btn-large btn-primary mr-2 mb-2"
          // color=""
          onClick={() => {
            window.open("//asg.shudhdesiporn.com/api/direct/265928", "_blank");
          }}
        >
          Download 1
        </a>
        {vt}
        {md}
        {ds}
      </>
    );
  };

  render() {
    return (
      <>
        <Navbar history={this.props.history} location={this.props.location} />
        <div id="wrapper">
          <Sidebar location={this.props.location} />
          <Helmet>
            <title>{this.state.title} | In Desi Porn we trust!</title>
            <link
              rel="canonical"
              href={`http://shudhdesiporn.com/video/${this.state.slug}`}
            />
            <link
              name="twitter:url"
              href={`http://shudhdesiporn.com/video/${this.state.slug}`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:description"
              content={`${this.state.description || `Watch ${this.state.title}. Only on ShudhDesiPorn.com`}`}
            />
            <meta name="twitter:title" content={`${this.state.title}`} />
            <meta name="twitter:site" content="@theashudhdesi" />
            <meta name="twitter:image" content={`${this.state.thumb}`} />
            <meta name="twitter:creator" content="@theashudhdesi" />
            {/* <!-- START - Open Graph for Facebook, Google+ and Twitter Card Tags 2.2.6.1 --> */}
            {/* <!-- Facebook Open Graph --> */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="Shudh Desi Porn" />
            <meta property="og:title" content={`${this.state.title}`} />
            <meta
              property="og:url"
              content={`http://shudhdesiporn.com/video/${this.state.slug}`}
            />
            <meta property="og:type" content="video" />
            <meta
              property="og:description"
              content={`${this.state.description || `Watch ${this.state.title}. Only on ShudhDesiPorn.com`}`}
            />
            <meta property="og:image" content={`${this.state.thumb}`} />
            <meta property="og:image:url" content={`${this.state.thumb}`} />
            <meta
              property="og:image:secure_url"
              content={`${this.state.thumb}`}
            />
            <meta
              property="article:published_time"
              content={`${this.state.date}`}
            />
            <meta
              property="article:modified_time"
              content={`${this.state.date}`}
            />
            <meta property="og:updated_time" content={`${this.state.date}`} />
            {/* <!-- Google+ / Schema.org --> */}
            <meta itemprop="name" content={`${this.state.title}`} />
            <meta itemprop="headline" content={`${this.state.title}`} />
            <meta
              itemprop="description"
              content={`${this.state.description || `Watch ${this.state.title}. Only on ShudhDesiPorn.com`}`}
            />
            <meta itemprop="image" content={`${this.state.thumb}`} />
            <meta itemprop="datePublished" content={`${this.state.date}`} />
            <meta itemprop="dateModified" content={`${this.state.date}`} />
            <meta itemprop="author" content="Shudh Desi Admin" />
          </Helmet>
          <div id="content-wrapper">
            <div className="container-fluid pb-0">
              <div className="video-block section-padding">
                <div
                  className="loading"
                  style={{
                    position: "fixed",
                    zIndex: "999",
                    height: "2em",
                    width: "2em",
                    overflow: "show",
                    margin: "auto",
                    top: "0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                  }}
                >
                  Loading...
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="single-video-left">
                      <div className="single-video">{this.video()}</div>
                      <div className="single-video-title box mb-3">
                        <h2>
                          {this.state.title}
                          <span className="float-right">
                            <a id="thumbsUp" href="##" onClick={this.thumbsUp}>
                              <i className="far fa-thumbs-up" />
                            </a>{" "}
                            {"       "}
                            <a
                              id="thumbsDown"
                              href="##"
                              onClick={this.thumbsDown}
                            >
                              <i className="far fa-thumbs-down" />
                            </a>
                          </span>
                        </h2>
                        <p className="mb-0">
                          <i className="fas fa-eye" /> {this.state.postViews}{" "}
                          views
                          <span id="votes" className="float-right">
                            Votes: {this.state.votesCount}
                          </span>
                        </p>
                      </div>
                      {/* <div className="single-video-author box mb-3">
                        <div className="float-right">
                          <button className="btn btn-danger" type="button">
                            Subscribe <strong>1.4M</strong>
                          </button>{" "}
                          <button
                            className="btn btn btn-outline-danger"
                            type="button"
                          >
                            <i className="fas fa-bell" />
                          </button>
                        </div>
                        <img className="img-fluid" src="img/s4.png" alt="" />
                        <p>
                          <Link href="##">
                            <strong>Osahan Channel</strong>
                          </Link>{" "}
                          <span
                            title=""
                            data-placement="top"
                            data-toggle="tooltip"
                            data-original-title="Verified"
                          >
                            <i className="fas fa-check-circle text-success" />
                          </span>
                        </p>
                        <small>Published on Aug 10, 2018</small>
                      </div> */}
                      {/* <a href="download"></a> */}
                      <div className="single-video-info-content box mb-3">
                        {/* <h6>Download:</h6> */}
                        <p>{this.downloadButtons()}</p>
                        {/* <h6>Category :</h6>
                        <p>Gaming , PS4 Exclusive , Gameplay , 1080p</p> */}
                        <h6>About :</h6>
                        <p>
                          <strong>NOTE:</strong>Use a VPN if you cannot access
                          any of the mirrors. Use{" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.cloudflare.onedotonedotonedotone"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            1.1.1.1{" "}
                          </a>
                          on android and something like nord vpn on PC. Just
                          google for it.
                        </p>
                        <p className="">
                          {this.state.title} only at{" "}
                          <Link to="http://shudhdesiporn.com">
                            shudhdesiporn.com
                          </Link>
                          <br />
                          Bookmark{" "}
                          <Link to="http://shudhdesiporn.com">
                            Shudh Desi Porn
                          </Link>{" "}
                          for the best desi porn videos!
                          {/* <br />For the best desi gallery please visit <Link href="https://gallery.shudhdesiporn.com">Shudh Desi Gallery</Link><br />For the best desi forum please visit <Link href="http://shudhdesiporn.com/forum">Shudh Desi Forum</Link> */}
                        </p>

                        {this.tags()}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="single-video-right">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="adblock">
                            {/* <!-- Ads Managed by AdSpyglass.com --> */}
                            {/* <iframe
                              key="ads"
                              title="ads"
                              className="na"
                              frameBorder="0"
                              scrolling="no"
                              width="336"
                              height="280"
                              sandbox="allow-scripts allow-popups allow-forms allow-same-origin"
                              src="//asg.shudhdesiporn.com/api/spots/265932?p=1"
                            ></iframe> */}
                            {/* <!-- Ads Managed by AdSpyglass.com --> */}
                            <iframe
                              key={this.state.videoSrc.replace(/(\r\n|\n|\r)/gm, "")}
                              title="ads"
                              className="na"
                              frameBorder="0"
                              scrolling="no"
                              width="336"
                              height="280"
                              sandbox="allow-scripts allow-popups allow-forms allow-same-origin"
                              src="//asg.shudhdesiporn.com/api/spots/266111?p=1"
                            ></iframe>

                            <div
                              id="_1772102"
                              className="_cfb7bc601a1624"
                            ></div>
                            {/* <div className="img">
                              Google AdSense
                              <br />
                              336 x 280 */}
                            {/* </div> */}
                          </div>
                          <div className="main-title">
                            <div className="btn-group float-right right-action">
                              <a
                                href="##"
                                className="right-action-link text-gray"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Sort by{" "}
                                <i
                                  className="fa fa-caret-down"
                                  aria-hidden="true"
                                />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="##"
                                  onClick={() => {
                                    this.setState({ sortBy: "votesCount" });
                                  }}
                                >
                                  <i className="fas fa-fw fa-star" /> &nbsp; Top
                                  Rated
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="##"
                                  onClick={() => {
                                    this.setState({ sortBy: "postViews" });
                                  }}
                                >
                                  <i className="fas fa-fw fa-signal" /> &nbsp;
                                  Viewed
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="##"
                                  onClick={() => {
                                    this.setState({ sortBy: "updatedAt" });
                                  }}
                                >
                                  <i className="fas fa-fw fa-clock" /> &nbsp;
                                  Recent
                                </a>
                              </div>
                            </div>
                            <h6>Up Next</h6>
                          </div>
                        </div>
                        <div className="col-md-12">{this.upNext()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default VideoPage;
