import React from "react";
import fluidPlayer from "fluid-player";

import "fluid-player/src/css/fluidplayer.css";

class MyVideo extends React.Component {
  constructor(props) {
    super(props);
    this.self = React.createRef();
    this.player = null;
  }

  render() {
    return (
      <video ref={this.self}>
        <source
          src={this.props.src}
          data-fluid-hd
          title="1080p"
          type="video/mp4"
        />
      </video>
    );
  }

  componentDidMount() {
    this.player = fluidPlayer(this.self.current, {
      layoutControls: {
        // Parameters to customise the look and feel of the player
        fillToContainer: true,
        preload: true,
      },
      vastOptions: {
        // Parameters to customise how the ads are displayed & behave
        adList: [
          {
            roll: "preRoll",
            vastTag: "//asg.shudhdesiporn.com/api/spots/294532?s1=%subid1%&kw=", // asg
            // vastTag: "https://nuevonoelmid.com/ceef/gdt3g0/tbt/1778813/tlk.xml", // clickadu
            // vastTag: "https://icn.brandnewapp.pro/v2/a/prl/vst/137904", //clickaine - does not work. -locally?
            // vastTag: "https://web.tidint.pro/v2/a/prl/vst/137904",
            // vastTag: "https://www.videosprofitnetwork.com/watch.xml?key=c61d4e87a3031a76d65d4594f0bf21f7", // Adsterra - does not work.  -locally?
            adText:
              "Advertising supports us directly. Contact us for premium ad free experience",
            fallbackVastTags: [
              "https://www.videosprofit.com/watch.xml?key=c61d4e87a3031a76d65d4594f0bf21f7", // Adsterra
              "https://icn.brandnewapp.pro/v2/a/prl/vst/137904", //clickaine
              "https://2737.cromo.name/v2/a/prl/vst/165029"
              // "https://nuevonoelmid.com/ceef/gdt3g0/tbt/1778813/tlk.xml", // clickadu
              // "https://lologhfd.com/ceef/gdt3g0/tbt/1778809/tlk.xml", //clickadu
            ],
          },
          {
            roll: "onPauseRoll",
            vastTag:
              // "https://web.tidint.pro/v2/a/prl/vst/137904",
              "https://www.videosprofit.com/watch.xml?key=c61d4e87a3031a76d65d4594f0bf21f7",
            // "https://lologhfd.com/ceef/gdt3g0/tbt/1778813/tlk.xml",
            adText:
              "Advertising supports us directly. Contact us for premium ad free experience",
            fallbackVastTags: [
              "https://web.tidint.pro/v2/a/prl/vst/137904",
              // "https://www.videosprofit.com/watch.xml?key=c61d4e87a3031a76d65d4594f0bf21f7",
              "https://lologhfd.com/ceef/gdt3g0/tbt/1778809/tlk.xml",
            ],
          },
        ],
        skipButtonCaption: "Skip ad in [seconds]",
        skipButtonClickCaption:
          'Skip ad <span class="skip_button_icon"></span>',

        vastTimeout: 8000,
        autoplay: true,
      },
    });
  }

  componentWillUnmount() {
    if (!!this.player) {
      this.player.destroy();
    }
  }
}

export default MyVideo;
